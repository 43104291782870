@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css);
.loader-wrapper {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: -1;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.loader {
    height: 50px;
    width: 50px;
}
/* .dnd {
    margin: 10px;
    transition: background-color .5s;
    cursor: pointer;
    box-sizing: border-box;
}
.dnd:hover {
    background-color: hsl(0, 0%, 97%);outline: 4px dashed hsl(0, 0%, 75%);

} */

.dragging {
    background-color: hsl(0, 0%, 96%);
    outline: 4px dashed hsl(0, 0%, 75%);
    box-sizing: border-box;
}

